.content-1{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.services-card-container-1{
  margin: 32px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  column-gap: 12px;
/* width: 100%; */
}
.services-card-container-1 h2{
  writing-mode:vertical-rl;
  color: var(--primary-color);
  text-decoration: overline;
}