/* PortfolioPage.css */

.portfolio {
    text-align: center;
    padding: 20px;
  }
  
  h2 {
    font-size: 28px;
    margin-bottom: 120px;
  }
  
  .project-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .project-card {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    max-width: 300px;

  }
  
  .project-card img {
    max-width: 100%;
width: 250px;
height: 250px;  
}
  
  .project-card h3 {
   color: var(--primary-color);
    font-size: 20px;
    margin-top: 10px;
  }
  
  .project-card p {
    margin-top: 10px;
    color: aliceblue;
  }
  