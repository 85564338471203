/* Reset some default browser styles */
body, h1, h2, p {
    margin: 0;
    padding: 0;
}

/* Global Styles */
body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    background-color: #f7f7f7;
    color: #333;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;

}


/* Section Styles */
section {
    /* background-color: #fff; */
    margin: 20px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
display: flex;
flex-wrap: wrap;
justify-content: space-around;
/* flex-direction: column; */
align-items: center;
max-height: fit-content;
}

/* Heading Styles */
.story-content{
    width: 50%;
}
.story img{
  max-width: 500px;
max-height: 600px;
}
h2 {
    font-size: 28px;
    color: #ffffff;
margin-left: 12px;
}
.story img{
    width: 50%;
}
.mission-content{
    width: 50%;
}
.mission img{
    width: 50%;
    max-width: 300px;
max-height: 600px;
transform: rotate(90deg);
}
.set-apart-content{
    width: 50%;
}
.sets-apart img{
    max-width: 500px;
max-height: 600px;
width: 50%;
}
/* Paragraph Styles */
p {
    font-size: 16px;
    line-height: 1.6;
    color: #ffffff;
    margin-left: 12px;
}
.light-theme p{
    color: #333;
}
/* List Styles */
ul {

    list-style: none;
    padding-left: 20px;
}

ul li {
    margin-bottom: 10px;
color: #f7f7f7;
}
.light-theme li{
    color: #333;
}
ul li strong{
    color: var(--primary-color);
}
/* Service Styles */
.service {
    margin-bottom: 30px;
}

.service h3 {
    font-size: 24px;
    /* color: #ffffff; */
    color: var(--primary-color);
}
.light-theme .service h3{
    color: var(--primary-color);
}
/* Contact Styles */
.contact {
    /* background-color: #f4f4f4; */
    text-align: center;
    padding: 30px 0;
}

.contact p {
    font-weight: bold;
    color: #ffffff;
}
.light-theme .contact p {
    /* font-weight: bold; */
    color: #000000;
}
.card {
    position: relative;
    width: 250px;
    height: 304px;
    background-color: #02022b;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px;
    gap: 12px;
    border-radius: 8px;
    cursor: pointer;
/* margin: 12px; */
}
  
  .card::before {
    content: '';
    position: absolute;
    inset: 0;
    left: -5px;
    margin: auto;
    width: 260px;
    height: 264px;
    border-radius: 10px;
    background: linear-gradient(-45deg, #e81cff 0%, #40c9ff 100% );
    z-index: -10;
    pointer-events: none;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  
  .card::after {
    content: "";
    z-index: -1;
    position: absolute;
    inset: 0;
    background: linear-gradient(-45deg, #fc00ff 0%, #00dbde 100% );
    transform: translate3d(0, 0, 0) scale(0.95);
    filter: blur(20px);
  }
  
  .heading {
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 700;
  }
  
  .card p:not(.heading) {
    font-size: 14px;
  }
  
  .card p:last-child {
    color: #e81cff;
    font-weight: 600;
  }
  
  .card:hover::after {
    filter: blur(30px);
  }
  
  .card:hover::before {
    transform: rotate(-90deg) scaleX(1.34) scaleY(0.77);
  }
  .card-container{
    display: flex;
 /* margin-top: 22px; */
    flex-direction: row;
    /* row-gap: 2rem; */
    column-gap: 2rem;
align-items: center;
justify-content: space-around;  
}
.light-theme .card{
    background-color: rgb(255, 255, 255);
}
@media only screen and (max-width:993px){
    .mission img{
    
        max-width: 400px;
    max-height: 380px;}   
    .card{
        width: 228px;       
    }
    .card::before{
        width: 238px;
    }
}
@media only screen and (max-width:768px){
    .card{
        width: 218px;       
    }
    .card::before{
        width: 228px;
    }
.card-container{
display:flex;
flex-direction: column;
row-gap: 2rem;
}
.commitment img{
    max-width :500px; 
max-height: 500px;
}
section {
    display: flex;
    flex-direction: column;
justify-content: center;
align-items: center;
}
.story-content{
    width: 90%;
}
.story img{
    width: 80%;
}
.mission-content{
    width: 90%
}
.mission img{
    width: 80%
}
.set-apart-content{
    width: 90%;
}
.sets-apart img{
width: 80%;
}
}
@media only screen and (max-width:568px){
    .commitment img{
        max-width: 340px;
        max-height: 340px;
    }
    .commitment{
    display: flex;
    flex-direction: column;    
    }
}