.services__top-content{
text-align: center;
margin-bottom: 50px;
}
.services__item-wrapper{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    column-gap: 1rem;
}
.services__item{
    background: var(--card-bg);
    width: 32%;
    padding: 30px 15px;
    height: 330px;
    text-align: center;
border-radius: 0.5rem;
cursor: pointer;
transition: all 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86);
margin-bottom: 20px;
}

.light-theme
.services__item{
    border: 1px solid var(--primary-color);
}
.services__item:hover{
    transform: scale(1.04);
    box-shadow: rgba(16, 96, 235, 0.618) 0px 20px 20px -25px;
  
}
.service__icon{
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background:var(--icon-bg) ;
    border-radius: 5px;
    margin: auto;
    border: 1px solid var(--primary-color);
    margin-bottom: 30px;
 }
 .light-theme 
 .service__icon{
    background-color: var(--primary-color);
 }
 .light-theme
  .service__icon i{
    color: #fff;
 }
.service__icon i{
    color: var(--primary-color);
    font-size: 3.4rem;
/* margin-bottom: 30px; */
}
.service__title{
    color: var(--primary-color);
}
.services__btn{
    border-radius: .25rem;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    padding-left: 25px;
    padding-right: 25px;
    color: #fff;
    height: 40px;
  font-size: 0.7rem;
  line-height: 14px;
  letter-spacing: 1.2px;
  transition: .2s .1s;
-webkit-clip-path: polygon(0 0,0 0,100% 0,100% 0,100% calc(100% - 15px),calc(100% - 15px) 100%,15px 100%,0 100%);
  clip-path: polygon(0 0,0 0,100% 0,100% 0,100% calc(100% - 15px),calc(100% - 15px) 100%,15px 100%,0 100%);
  border: 0 solid;
  overflow: hidden;
  background: var(--btn-primary-bg);
    
}
   .light-theme .services__btn{
    border-radius: .25rem;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    padding-left: 25px;
    padding-right: 25px;
    color: #fff;
    height: 40px;
  font-size: 0.7rem;
  line-height: 14px;
  letter-spacing: 1.2px;
  transition: .2s .1s;
-webkit-clip-path: polygon(0 0,0 0,100% 0,100% 0,100% calc(100% - 15px),calc(100% - 15px) 100%,15px 100%,0 100%);
  clip-path: polygon(0 0,0 0,100% 0,100% 0,100% calc(100% - 15px),calc(100% - 15px) 100%,15px 100%,0 100%);
  border: 0 solid;
  overflow: hidden;
  background: var(--btn-secondary-bg);
    
}
.services__btn:hover{
cursor: pointer;
transition: all .3s ease-in;
padding-right: 30px;
padding-left: 30px;
}
@media only screen and (max-width:992px){
.services__top-content h2{
  font-size: 1.5rem;
}
.services__item-wrapper{
 
  flex-wrap: wrap;
}
.services__item{
  width: 47%;
  margin-bottom: 30px;
}
}
@media only screen and (max-width:768px){
  
  .services__item{
    width: 100%;
    margin-bottom: 30px;
  }
  .service__title{
    font-size: 1.2rem;
  }
  }
  @media only screen and (max-width:576px){
  
    .services__item{
      width: 100%;
      margin-bottom: 30px;
    }

    }