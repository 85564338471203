.contactpage__details{
    display: flex;
    column-gap: 0rem;
    align-items: center;
    justify-content: space-evenly;
    align-content: space-between;
    flex-wrap: wrap;    
    
}
.light-theme .contact__details form{
    background: linear-gradient(90deg,#e3d8e9 0%, #ffe7e7 80%);
    
}
.light-theme  .contact__details form label{
color: #6640e4;
}
.contact__details h2 span{
color:var(--btn-primary-bg);
}
.light-theme .contact__details h2 span{
    color:var(--btn-secondary-bg);
    }
.contact__details form{
    background: linear-gradient(90deg,#b9a5fb 20%, #9ac1fe 80%);
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    padding: 23px;
    margin: 23px;
    border-radius: 23px 23px 23px 23px;
    box-shadow: 0px 0px 26px 0px var(--btn-primary-bg);
}
.light-theme .contact__details form{
    box-shadow: 0px 0px 26px 0px var(--btn-secondary-bg);
}
.contact__img img{
    width: 400px;
    height:400px;
  
}
.contact__form label{
    color:#ffffff;
    font-size: 1.2rem;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    margin:12px 0;
}
.light-theme ,
 .contact__form label{
    color: #000;
}
.contact__details form button{
    width: 150px;
    margin-top: 12px;
}
.contact__details input{
    width: 254px;
    height:44px;
    border-radius: 10px;
padding: 12px;
}
.contact__details textarea{
    width: 400px;
    height: 300px;
    border-radius: 15px;
    padding: 12px;
}
.secondary__btn{          
    border-radius: .25rem;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    padding-left: 25px;
    padding-right: 25px;
    color: #fff;
    height: 40px;
  font-size: 0.7rem;
  line-height: 14px;
  letter-spacing: 1.2px;
  transition: .2s .1s;
-webkit-clip-path: polygon(0 0,0 0,100% 0,100% 0,100% calc(100% - 15px),calc(100% - 15px) 100%,15px 100%,0 100%);
  clip-path: polygon(0 0,0 0,100% 0,100% 0,100% calc(100% - 15px),calc(100% - 15px) 100%,15px 100%,0 100%);
  border: 0 solid;
  overflow: hidden;
}
.light-theme .secondary__btn{          
    border-radius: .25rem;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    padding-left: 25px;
    padding-right: 25px;
    color: #fff;
    height: 40px;
  font-size: 0.7rem;
  line-height: 14px;
  letter-spacing: 1.2px;
  transition: .2s .1s;
-webkit-clip-path: polygon(0 0,0 0,100% 0,100% 0,100% calc(100% - 15px),calc(100% - 15px) 100%,15px 100%,0 100%);
  clip-path: polygon(0 0,0 0,100% 0,100% 0,100% calc(100% - 15px),calc(100% - 15px) 100%,15px 100%,0 100%);
  border: 0 solid;
  overflow: hidden;
  background: var(--btn-secondary-bg);

}
.secondary__btn{
    background: var(--btn-primary-bg);
}
 .secondary__btn:hover{
    cursor: pointer;
  transition: all .3s ease-in;
  padding-right: 30px;
  padding-left: 30px;
}
@media only screen and (max-width:567px){
    .contact__details form{
        max-width: 330px;
        max-height: 400px;
    }
    .contact__details textarea{
        width: 280px;
        height: 250px;
 
    }
.contact__details h2{
    font-size: 32px;
}
}