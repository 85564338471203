.hero__wrapper{
    display: flex;
    justify-content: space-between;
}.hero__content, .hero__img{
    width: 50%;
height: fit-content;
  }
.hero__img img{
    width: 100%;
  }
.hero__content{
    padding-top: 40px;
}
.hero__content h2{
font-style: 3rem;
}
.hero__content p{
        margin-top: 40px;
font-size: 1.1rem;
line-height: 65px;
}
.highlight{
    color: var(--primary-color);
}
.hero__btn{
margin-top: 40px;
display: flex;
align-items: center;

}
.secondary__btn{          
    border-radius: .25rem;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    padding-left: 25px;
    padding-right: 25px;
    color: #fff;
    height: 40px;
  font-size: 0.7rem;
  line-height: 14px;
  letter-spacing: 1.2px;
  transition: .2s .1s;
-webkit-clip-path: polygon(0 0,0 0,100% 0,100% 0,100% calc(100% - 15px),calc(100% - 15px) 100%,15px 100%,0 100%);
  clip-path: polygon(0 0,0 0,100% 0,100% 0,100% calc(100% - 15px),calc(100% - 15px) 100%,15px 100%,0 100%);
  border: 0 solid;
  overflow: hidden;

}
.light-theme .secondary__btn{          
    border-radius: .25rem;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    padding-left: 25px;
    padding-right: 25px;
    color: #fff;
    height: 40px;
  font-size: 0.7rem;
  line-height: 14px;
  letter-spacing: 1.2px;
  transition: .2s .1s;
-webkit-clip-path: polygon(0 0,0 0,100% 0,100% 0,100% calc(100% - 15px),calc(100% - 15px) 100%,15px 100%,0 100%);
  clip-path: polygon(0 0,0 0,100% 0,100% 0,100% calc(100% - 15px),calc(100% - 15px) 100%,15px 100%,0 100%);
  border: 0 solid;
  overflow: hidden;
  background: var(--btn-secondary-bg);

}
.secondary__btn{
    background: var(--btn-primary-bg);
}
 .secondary__btn:hover{
    cursor: pointer;
  transition: all .3s ease-in;
  padding-right: 30px;
  padding-left: 30px;
}
@media only screen and (max-width: 992px) {
  .hero__content h2{
    font-size: 2rem;
    line-height: 50px;
  }
}
@media only screen and (max-width: 768px){
  .hero__content h2{
    font-size: 1.5rem;
    line-height: 45px;
  }
  .hero__content p{
    font-size: 0.9rem;

  }
  .hero__btn{
    font-size: 0.8rem;
  }
  .hero__content,
  .hero__img{
    width: 100%;
  }
}
@media only screen and (max-width: 600px){
  .hero__content,
  .hero__img{
    width: 100%;
  }
  .hero__wrapper{
    flex-direction: column;
  }
}