.header{
    width: 100%;
    height: 80px;
    line-height: 80px;
}

.nav__wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.menu{
    display: flex;
    align-items: center;
column-gap: 2.7rem;
list-style: none;
color: #f7f7f7;
}

.menu__link{
    font-weight: 500;
    font-size: .9rem;
    cursor: pointer;
    /* color: #f7f7f7; */
}
.menu__link:active{
    color: var(--link-active);
}
.light-theme .menu__link:active{
    color: var(--primary-color);
}
.menu__link:hover{
    color: var(--link-active);
}
.light-theme .menu__link:hover{
    color: var(--primary-color);
}
.logo h2{
    color: var(--primary-color);
}
.light__mode span{
    color: #fff;
    display: flex;
align-items: center;
column-gap: 0.4rem;
font-size: 1.2rem;
cursor: pointer;
}
.light-theme .light__mode span{
    color: #091783;
    font-size: 1.2rem;
    cursor: pointer;
}
.header__shrink{
    width: 100%;
    height: 80px;
    line-height: 80px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: #0c123d;
    border-radius: 1px 0px 67px 67px; 
     box-shadow: 0px 10px 49px -22px var(--secondary-color);
}
.light-theme .header__shrink{
    background: #f7f7f7;
    border-radius: 1px 0px 67px 67px; 
     box-shadow: 0px 10px 49px -22px var(--primary-color);

}
 .mobile__menu{
    color: #fff;
    font-size: 1.3rem;
    cursor: pointer;
    display: none;
 }
 
 .light-theme .mobile__menu{
    color: #000000;
 }

 /* responsive */

 @media only screen and (max-width:992px){

    .navigation{
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(6, 11, 26, 0.868);
z-index: 99999;
display: none;
}
.menu{
    flex-direction: column;
    background: #0c123d;
    width: 250px;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: absolute;
    color: #fff;
    top: 0;
    right: 0;
    z-index: 999999;
}
.light-theme .menu{
background: #7c37b4;
}
.menu__link{
    color: #f7f7f7;
}
.menu__active{
    display: block;

}
.mobile__menu{
    display: block;
}
 }
 @media only screen and (max-width:1024px){
    .container{
width: 100%;
    }
 }
 @media only screen and (max-width:768px){
    .header{
        width: 100%;
        height: 50px;
        line-height: 50px;

    }
    .mobile__menu{
        font-size: 1rem;
    }
    .logo h2{
        font-size: 1.3rem;
    }
 }