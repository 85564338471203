@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
.header{
    width: 100%;
    height: 80px;
    line-height: 80px;
}

.nav__wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.menu{
    display: flex;
    align-items: center;
grid-column-gap: 2.7rem;
-webkit-column-gap: 2.7rem;
        column-gap: 2.7rem;
list-style: none;
color: #f7f7f7;
}

.menu__link{
    font-weight: 500;
    font-size: .9rem;
    cursor: pointer;
    /* color: #f7f7f7; */
}
.menu__link:active{
    color: var(--link-active);
}
.light-theme .menu__link:active{
    color: var(--primary-color);
}
.menu__link:hover{
    color: var(--link-active);
}
.light-theme .menu__link:hover{
    color: var(--primary-color);
}
.logo h2{
    color: var(--primary-color);
}
.light__mode span{
    color: #fff;
    display: flex;
align-items: center;
grid-column-gap: 0.4rem;
-webkit-column-gap: 0.4rem;
        column-gap: 0.4rem;
font-size: 1.2rem;
cursor: pointer;
}
.light-theme .light__mode span{
    color: #091783;
    font-size: 1.2rem;
    cursor: pointer;
}
.header__shrink{
    width: 100%;
    height: 80px;
    line-height: 80px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: #0c123d;
    border-radius: 1px 0px 67px 67px; 
     box-shadow: 0px 10px 49px -22px var(--secondary-color);
}
.light-theme .header__shrink{
    background: #f7f7f7;
    border-radius: 1px 0px 67px 67px; 
     box-shadow: 0px 10px 49px -22px var(--primary-color);

}
 .mobile__menu{
    color: #fff;
    font-size: 1.3rem;
    cursor: pointer;
    display: none;
 }
 
 .light-theme .mobile__menu{
    color: #000000;
 }

 /* responsive */

 @media only screen and (max-width:992px){

    .navigation{
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(6, 11, 26, 0.868);
z-index: 99999;
display: none;
}
.menu{
    flex-direction: column;
    background: #0c123d;
    width: 250px;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: absolute;
    color: #fff;
    top: 0;
    right: 0;
    z-index: 999999;
}
.light-theme .menu{
background: #7c37b4;
}
.menu__link{
    color: #f7f7f7;
}
.menu__active{
    display: block;

}
.mobile__menu{
    display: block;
}
 }
 @media only screen and (max-width:1024px){
    .container{
width: 100%;
    }
 }
 @media only screen and (max-width:768px){
    .header{
        width: 100%;
        height: 50px;
        line-height: 50px;

    }
    .mobile__menu{
        font-size: 1rem;
    }
    .logo h2{
        font-size: 1.3rem;
    }
 }
.hero__wrapper{
    display: flex;
    justify-content: space-between;
}.hero__content, .hero__img{
    width: 50%;
height: -webkit-fit-content;
height: -moz-fit-content;
height: fit-content;
  }
.hero__img img{
    width: 100%;
  }
.hero__content{
    padding-top: 40px;
}
.hero__content h2{
font-style: 3rem;
}
.hero__content p{
        margin-top: 40px;
font-size: 1.1rem;
line-height: 65px;
}
.highlight{
    color: var(--primary-color);
}
.hero__btn{
margin-top: 40px;
display: flex;
align-items: center;

}
.secondary__btn{          
    border-radius: .25rem;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    padding-left: 25px;
    padding-right: 25px;
    color: #fff;
    height: 40px;
  font-size: 0.7rem;
  line-height: 14px;
  letter-spacing: 1.2px;
  transition: .2s .1s;
-webkit-clip-path: polygon(0 0,0 0,100% 0,100% 0,100% calc(100% - 15px),calc(100% - 15px) 100%,15px 100%,0 100%);
  clip-path: polygon(0 0,0 0,100% 0,100% 0,100% calc(100% - 15px),calc(100% - 15px) 100%,15px 100%,0 100%);
  border: 0 solid;
  overflow: hidden;

}
.light-theme .secondary__btn{          
    border-radius: .25rem;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    padding-left: 25px;
    padding-right: 25px;
    color: #fff;
    height: 40px;
  font-size: 0.7rem;
  line-height: 14px;
  letter-spacing: 1.2px;
  transition: .2s .1s;
-webkit-clip-path: polygon(0 0,0 0,100% 0,100% 0,100% calc(100% - 15px),calc(100% - 15px) 100%,15px 100%,0 100%);
  clip-path: polygon(0 0,0 0,100% 0,100% 0,100% calc(100% - 15px),calc(100% - 15px) 100%,15px 100%,0 100%);
  border: 0 solid;
  overflow: hidden;
  background: var(--btn-secondary-bg);

}
.secondary__btn{
    background: var(--btn-primary-bg);
}
 .secondary__btn:hover{
    cursor: pointer;
  transition: all .3s ease-in;
  padding-right: 30px;
  padding-left: 30px;
}
@media only screen and (max-width: 992px) {
  .hero__content h2{
    font-size: 2rem;
    line-height: 50px;
  }
}
@media only screen and (max-width: 768px){
  .hero__content h2{
    font-size: 1.5rem;
    line-height: 45px;
  }
  .hero__content p{
    font-size: 0.9rem;

  }
  .hero__btn{
    font-size: 0.8rem;
  }
  .hero__content,
  .hero__img{
    width: 100%;
  }
}
@media only screen and (max-width: 600px){
  .hero__content,
  .hero__img{
    width: 100%;
  }
  .hero__wrapper{
    flex-direction: column;
  }
}
.counter__wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.counter__item{
    width: 33%;
text-align: center;
}
.counter__number, .counter__title{
    background: var(--counter-color);
    background-size: 100% 100%;
    -webkit-background-clip: text;
            background-clip: text;
    -webkit-text-fill-color: transparent;
}
.counter__number{
    font-size: 2.5rem;
margin-bottom: 10px;
}
.counter__title{
    font-size: 1.5rem;
}
@media only  screen and (max-width:992px){
    .counter__number{
        font-size: 2rem;

    }
.counter__title{
    font-size: 1.2rem;
}
.counter__item{
    width: 30%;
}
}
@media only  screen and (max-width:768px){
    .counter__number{
        font-size: 1.5rem;

    }
.counter__title{
    font-size: 1rem;
}

}
@media only  screen and (max-width:576px){
    .counter__wrapper{
        flex-direction: column;

    }
.counter__item{
    width: 100%;
    margin-bottom: 30px;
}

}

.services__top-content{
text-align: center;
margin-bottom: 50px;
}
.services__item-wrapper{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
}
.services__item{
    background: var(--card-bg);
    width: 32%;
    padding: 30px 15px;
    height: 330px;
    text-align: center;
border-radius: 0.5rem;
cursor: pointer;
transition: all 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86);
margin-bottom: 20px;
}

.light-theme
.services__item{
    border: 1px solid var(--primary-color);
}
.services__item:hover{
    -webkit-transform: scale(1.04);
            transform: scale(1.04);
    box-shadow: rgba(16, 96, 235, 0.618) 0px 20px 20px -25px;
  
}
.service__icon{
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background:var(--icon-bg) ;
    border-radius: 5px;
    margin: auto;
    border: 1px solid var(--primary-color);
    margin-bottom: 30px;
 }
 .light-theme 
 .service__icon{
    background-color: var(--primary-color);
 }
 .light-theme
  .service__icon i{
    color: #fff;
 }
.service__icon i{
    color: var(--primary-color);
    font-size: 3.4rem;
/* margin-bottom: 30px; */
}
.service__title{
    color: var(--primary-color);
}
.services__btn{
    border-radius: .25rem;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    padding-left: 25px;
    padding-right: 25px;
    color: #fff;
    height: 40px;
  font-size: 0.7rem;
  line-height: 14px;
  letter-spacing: 1.2px;
  transition: .2s .1s;
-webkit-clip-path: polygon(0 0,0 0,100% 0,100% 0,100% calc(100% - 15px),calc(100% - 15px) 100%,15px 100%,0 100%);
  clip-path: polygon(0 0,0 0,100% 0,100% 0,100% calc(100% - 15px),calc(100% - 15px) 100%,15px 100%,0 100%);
  border: 0 solid;
  overflow: hidden;
  background: var(--btn-primary-bg);
    
}
   .light-theme .services__btn{
    border-radius: .25rem;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    padding-left: 25px;
    padding-right: 25px;
    color: #fff;
    height: 40px;
  font-size: 0.7rem;
  line-height: 14px;
  letter-spacing: 1.2px;
  transition: .2s .1s;
-webkit-clip-path: polygon(0 0,0 0,100% 0,100% 0,100% calc(100% - 15px),calc(100% - 15px) 100%,15px 100%,0 100%);
  clip-path: polygon(0 0,0 0,100% 0,100% 0,100% calc(100% - 15px),calc(100% - 15px) 100%,15px 100%,0 100%);
  border: 0 solid;
  overflow: hidden;
  background: var(--btn-secondary-bg);
    
}
.services__btn:hover{
cursor: pointer;
transition: all .3s ease-in;
padding-right: 30px;
padding-left: 30px;
}
@media only screen and (max-width:992px){
.services__top-content h2{
  font-size: 1.5rem;
}
.services__item-wrapper{
 
  flex-wrap: wrap;
}
.services__item{
  width: 47%;
  margin-bottom: 30px;
}
}
@media only screen and (max-width:768px){
  
  .services__item{
    width: 100%;
    margin-bottom: 30px;
  }
  .service__title{
    font-size: 1.2rem;
  }
  }
  @media only screen and (max-width:576px){
  
    .services__item{
      width: 100%;
      margin-bottom: 30px;
    }

    }

.about__wrapper{
    display: flex;
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
    /* height: 600px; */
}
.about__content,
.about__img{
    width: 50%;
    height: 100%;

}
.about__img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
}
.about__content-desc{
    margin-top: 30px;
}
.choose__us-item{
    padding: 20px 20px 20px 0px;
    display: flex;
    align-items: center;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
}
.choose__us-icon{
    width: 50px;
height: 60px;
padding: 0px 30px;
display: flex;
align-items: center;
justify-content: center;
border: 1px solid var(--primary-color);
border-radius: 5px;
}
.choose__us-icon i{
    color: var(--primary-color);
    font-size: 2rem;
}
.choose__us-title{
    color: #fff;
    font-weight: 500;

}
.light-theme .choose__us-title{
    color: #0c123d;
}
@media only screen and (max-width:992px){
    .about__content h2{
        font-size: 1.5rem;

    }
}
@media only screen and (max-width:768px){
    .about__wrapper{
        flex-direction: column;
    }
    .about__content, .about__img{
        width: 100%;
    }
    .about__content{
        margin-bottom: 30px;
    }
    .about__img{
        height: 400px !important;
    }
}

.slider__content-top{
    text-align: center;
    margin-bottom: 50px;

}
.slider__wrapper{
    width: 70%;
    margin: auto;

}
.slider__item{
    background: var(--testimonial-bg);
    padding: 40px 30px;
    border-radius: 0.5rem;
cursor: pointer;
}
.slider__item p{
    margin-bottom: 30px;

}
.customer__details{
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
margin-top: 40px;
}
.customer__img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
}
.customer__img img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}
.customer__name{
    font-size: 1.2rem;
    color: white;
/* media only screen and (max-width:768px){ */
  }
.light-theme .customer__name{
    font-size: 1.2rem;
    color: #091783;
}
@media only screen and (max-width:992px){
     .slider__content-top h2{
        font-size: 1.5rem;
    }
}
@media only screen and (max-width:786px){
    .slider__wrapper{
       width: 90%;
   }
}
.newsletter{
    background: var(--newsletter-bg);
}
.newsletter__wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.newsletter__content,
.newsletter__form{
width: 50%;
}
.newsletter__form input{
    padding: 0.7rem 1.5rem;
    border: none;
    outline: none;
    font-size: 1rem;
    cursor: text;
}
.newsletter__form{
    padding: 0.4rem;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.5rem;
}
.light-theme .newsletter{
    background: linear-gradient(
        180deg,
        rgba(189, 243, 255, 1) 29%,
        rgba(193, 243, 255, 1) 50%,
        rgba(226, 250, 255, 1) 78%
      ); ;
}
.light-theme .subscribe__btn{
    background: var(--primary-color);
    color: #fff;
}
@media only screen and (max-width:992px){
.newsletter__content h2{
    font-size: 1.5rem;
}
}
@media only screen and (max-width:768px){
    .newsletter__wrapper{
       flex-wrap: wrap;
    }
    
    .newsletter__content, .newsletter__form {
        width: 100%;
    }
    .newsletter__content{
        margin-bottom: 30px;
    }
    .newsletter__form input{
        padding: 8px 20px;
        font-style: 0.9rem;
    }
}

.footer{
    padding: 60px 0px;
}
.footer__wrapper{
    display: flex;
    justify-content: space-between;
grid-column-gap: 2rem;
-webkit-column-gap: 2rem;
        column-gap: 2rem;
}
.footer__logo{
    width: 40%;
}
.footer__quick-links{
    width: 20%;
}
.footer__logo h2{
    color: var(--primary-color);
}
.small__text{
    font-size: 1rem;
    margin-top: 30px;
}
.quick__links-title{
    font-size: 1.1rem;
    color: var(--primary-color);
    font-weight: 400;
margin-bottom: 30px;
}
.quick__links{
    list-style: none;}
.quick__link-item{
    margin-bottom: 30px;
}
.quick__link-item a{
    font-size: 0.9rem;
    font-weight: 300;
    cursor: pointer;
}
.quick__link-item a:hover{
    color: var(--primary-color);
}
.copyright{
    color: rgba(255, 255, 255, 0.573);
    margin-top: 40px;
    text-align: center;
    font-size: .9rem;
    font-weight: 500;
}
.light-theme .quick__link-item a{
    font-weight: 500;
}
.light-theme 
.copyright{
    font-weight: 500;
    color: #555;
}
@media only screen and (max-width:768px){
    .footer__logo{
        width: 55%;
    }
    .footer__wrapper{
        flex-wrap: wrap;
        grid-column-gap: 1.5rem !important;
        -webkit-column-gap: 1.5rem !important;
                column-gap: 1.5rem !important;
grid-row-gap: 1.5rem;
row-gap: 1.5rem;
    }
    /* .footer__quick-links{
        margin-bottom: 30px;
    } */
    .footer__logo h2{
        font-size:1.5rem ;
    }
}
@media only screen and (max-width:576px){
    .footer__quick-links{
        width: 47%;
    }
    .footer__logo{
        width: 47%;
    }
}

.content-1{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.services-card-container-1{
  margin: 32px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  grid-column-gap: 12px;
  -webkit-column-gap: 12px;
          column-gap: 12px;
/* width: 100%; */
}
.services-card-container-1 h2{
  -webkit-writing-mode:vertical-rl;
          writing-mode:vertical-rl;
  color: var(--primary-color);
  text-decoration: overline;
}
.contactpage__details{
    display: flex;
    grid-column-gap: 0rem;
    -webkit-column-gap: 0rem;
            column-gap: 0rem;
    align-items: center;
    justify-content: space-evenly;
    align-content: space-between;
    flex-wrap: wrap;    
    
}
.light-theme .contact__details form{
    background: linear-gradient(90deg,#e3d8e9 0%, #ffe7e7 80%);
    
}
.light-theme  .contact__details form label{
color: #6640e4;
}
.contact__details h2 span{
color:var(--btn-primary-bg);
}
.light-theme .contact__details h2 span{
    color:var(--btn-secondary-bg);
    }
.contact__details form{
    background: linear-gradient(90deg,#b9a5fb 20%, #9ac1fe 80%);
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    padding: 23px;
    margin: 23px;
    border-radius: 23px 23px 23px 23px;
    box-shadow: 0px 0px 26px 0px var(--btn-primary-bg);
}
.light-theme .contact__details form{
    box-shadow: 0px 0px 26px 0px var(--btn-secondary-bg);
}
.contact__img img{
    width: 400px;
    height:400px;
  
}
.contact__form label{
    color:#ffffff;
    font-size: 1.2rem;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    margin:12px 0;
}
.light-theme ,
 .contact__form label{
    color: #000;
}
.contact__details form button{
    width: 150px;
    margin-top: 12px;
}
.contact__details input{
    width: 254px;
    height:44px;
    border-radius: 10px;
padding: 12px;
}
.contact__details textarea{
    width: 400px;
    height: 300px;
    border-radius: 15px;
    padding: 12px;
}
.secondary__btn{          
    border-radius: .25rem;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    padding-left: 25px;
    padding-right: 25px;
    color: #fff;
    height: 40px;
  font-size: 0.7rem;
  line-height: 14px;
  letter-spacing: 1.2px;
  transition: .2s .1s;
-webkit-clip-path: polygon(0 0,0 0,100% 0,100% 0,100% calc(100% - 15px),calc(100% - 15px) 100%,15px 100%,0 100%);
  clip-path: polygon(0 0,0 0,100% 0,100% 0,100% calc(100% - 15px),calc(100% - 15px) 100%,15px 100%,0 100%);
  border: 0 solid;
  overflow: hidden;
}
.light-theme .secondary__btn{          
    border-radius: .25rem;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    padding-left: 25px;
    padding-right: 25px;
    color: #fff;
    height: 40px;
  font-size: 0.7rem;
  line-height: 14px;
  letter-spacing: 1.2px;
  transition: .2s .1s;
-webkit-clip-path: polygon(0 0,0 0,100% 0,100% 0,100% calc(100% - 15px),calc(100% - 15px) 100%,15px 100%,0 100%);
  clip-path: polygon(0 0,0 0,100% 0,100% 0,100% calc(100% - 15px),calc(100% - 15px) 100%,15px 100%,0 100%);
  border: 0 solid;
  overflow: hidden;
  background: var(--btn-secondary-bg);

}
.secondary__btn{
    background: var(--btn-primary-bg);
}
 .secondary__btn:hover{
    cursor: pointer;
  transition: all .3s ease-in;
  padding-right: 30px;
  padding-left: 30px;
}
@media only screen and (max-width:567px){
    .contact__details form{
        max-width: 330px;
        max-height: 400px;
    }
    .contact__details textarea{
        width: 280px;
        height: 250px;
 
    }
.contact__details h2{
    font-size: 32px;
}
}
/* PortfolioPage.css */

.portfolio {
    text-align: center;
    padding: 20px;
  }
  
  h2 {
    font-size: 28px;
    margin-bottom: 120px;
  }
  
  .project-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 20px;
    gap: 20px;
  }
  
  .project-card {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    max-width: 300px;

  }
  
  .project-card img {
    max-width: 100%;
width: 250px;
height: 250px;  
}
  
  .project-card h3 {
   color: var(--primary-color);
    font-size: 20px;
    margin-top: 10px;
  }
  
  .project-card p {
    margin-top: 10px;
    color: aliceblue;
  }
  
/* Reset some default browser styles */
body, h1, h2, p {
    margin: 0;
    padding: 0;
}

/* Global Styles */
body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    background-color: #f7f7f7;
    color: #333;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;

}


/* Section Styles */
section {
    /* background-color: #fff; */
    margin: 20px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
display: flex;
flex-wrap: wrap;
justify-content: space-around;
/* flex-direction: column; */
align-items: center;
max-height: -webkit-fit-content;
max-height: -moz-fit-content;
max-height: fit-content;
}

/* Heading Styles */
.story-content{
    width: 50%;
}
.story img{
  max-width: 500px;
max-height: 600px;
}
h2 {
    font-size: 28px;
    color: #ffffff;
margin-left: 12px;
}
.story img{
    width: 50%;
}
.mission-content{
    width: 50%;
}
.mission img{
    width: 50%;
    max-width: 300px;
max-height: 600px;
-webkit-transform: rotate(90deg);
        transform: rotate(90deg);
}
.set-apart-content{
    width: 50%;
}
.sets-apart img{
    max-width: 500px;
max-height: 600px;
width: 50%;
}
/* Paragraph Styles */
p {
    font-size: 16px;
    line-height: 1.6;
    color: #ffffff;
    margin-left: 12px;
}
.light-theme p{
    color: #333;
}
/* List Styles */
ul {

    list-style: none;
    padding-left: 20px;
}

ul li {
    margin-bottom: 10px;
color: #f7f7f7;
}
.light-theme li{
    color: #333;
}
ul li strong{
    color: var(--primary-color);
}
/* Service Styles */
.service {
    margin-bottom: 30px;
}

.service h3 {
    font-size: 24px;
    /* color: #ffffff; */
    color: var(--primary-color);
}
.light-theme .service h3{
    color: var(--primary-color);
}
/* Contact Styles */
.contact {
    /* background-color: #f4f4f4; */
    text-align: center;
    padding: 30px 0;
}

.contact p {
    font-weight: bold;
    color: #ffffff;
}
.light-theme .contact p {
    /* font-weight: bold; */
    color: #000000;
}
.card {
    position: relative;
    width: 250px;
    height: 304px;
    background-color: #02022b;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px;
    grid-gap: 12px;
    gap: 12px;
    border-radius: 8px;
    cursor: pointer;
/* margin: 12px; */
}
  
  .card::before {
    content: '';
    position: absolute;
    inset: 0;
    left: -5px;
    margin: auto;
    width: 260px;
    height: 264px;
    border-radius: 10px;
    background: linear-gradient(-45deg, #e81cff 0%, #40c9ff 100% );
    z-index: -10;
    pointer-events: none;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  
  .card::after {
    content: "";
    z-index: -1;
    position: absolute;
    inset: 0;
    background: linear-gradient(-45deg, #fc00ff 0%, #00dbde 100% );
    -webkit-transform: translate3d(0, 0, 0) scale(0.95);
            transform: translate3d(0, 0, 0) scale(0.95);
    -webkit-filter: blur(20px);
            filter: blur(20px);
  }
  
  .heading {
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 700;
  }
  
  .card p:not(.heading) {
    font-size: 14px;
  }
  
  .card p:last-child {
    color: #e81cff;
    font-weight: 600;
  }
  
  .card:hover::after {
    -webkit-filter: blur(30px);
            filter: blur(30px);
  }
  
  .card:hover::before {
    -webkit-transform: rotate(-90deg) scaleX(1.34) scaleY(0.77);
            transform: rotate(-90deg) scaleX(1.34) scaleY(0.77);
  }
  .card-container{
    display: flex;
 /* margin-top: 22px; */
    flex-direction: row;
    /* row-gap: 2rem; */
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
align-items: center;
justify-content: space-around;  
}
.light-theme .card{
    background-color: rgb(255, 255, 255);
}
@media only screen and (max-width:993px){
    .mission img{
    
        max-width: 400px;
    max-height: 380px;}   
    .card{
        width: 228px;       
    }
    .card::before{
        width: 238px;
    }
}
@media only screen and (max-width:768px){
    .card{
        width: 218px;       
    }
    .card::before{
        width: 228px;
    }
.card-container{
display:flex;
flex-direction: column;
grid-row-gap: 2rem;
row-gap: 2rem;
}
.commitment img{
    max-width :500px; 
max-height: 500px;
}
section {
    display: flex;
    flex-direction: column;
justify-content: center;
align-items: center;
}
.story-content{
    width: 90%;
}
.story img{
    width: 80%;
}
.mission-content{
    width: 90%
}
.mission img{
    width: 80%
}
.set-apart-content{
    width: 90%;
}
.sets-apart img{
width: 80%;
}
}
@media only screen and (max-width:568px){
    .commitment img{
        max-width: 340px;
        max-height: 340px;
    }
    .commitment{
    display: flex;
    flex-direction: column;    
    }
}
/* google fonts */

/* css variables */
:root {
  --primary-color: #379cf6;
  --heading-color: #fff;
  --btn-primary-bg: #379cf6;
  --btn-secondary-bg: #090238;
  --team-card-bg: #1b2352;
  --link-color: #fff;
  --link-active: #379cf6;
  --card-bg: linear-gradient(
    45deg,
    rgba(15, 18, 61, 1) 33%,
    rgba(15, 18, 61, 1) 52%,
    rgba(11, 41, 69, 1) 84%
  );
  --counter-color: linear-gradient(
  90deg, rgb(79, 72, 213) 0%, rgba(97,9,121,1) 35%, rgba(0,212,255,1) 100%);

  --body-bg: rgba(6, 11, 26, 1);
  --newsletter-bg: linear-gradient(
    90deg,
    rgba(27, 9, 115, 1) 20%,
    rgba(23, 9, 96, 1) 38%,
    rgba(14, 9, 56, 1) 100%
  );
  --testimonial-bg: #1a0f4f;
  --font-name: "Roboto", sans-serif;
  --small-text-color: rgba(255, 255, 255, 0.774);
  --icon-bg:#151e4f;
}

.light-theme {
  --body-bg: rgb(255, 255, 255);
  --heading-color: #0c123d;
  --btn-secondary-bg: #816aff;
  --btn-primary-bg: #fff;
  --primary-color: #816aff;
  --counter-color: #fff;
  --counter-section-bg: #816aff;
  --link-color: #0c123d;
  --link-active: #816aff;
  --newsletter-bg: linear-gradient(
    180deg,
    rgba(189, 243, 255, 1) 29%,
    rgba(193, 243, 255, 1) 50%,
    rgba(226, 250, 255, 1) 78%
  );
  --small-text-color:#000;
  --card-bg:#fff;
  --testimonial-bg: #f7f7f7;
  --team-card-bg: #fff;
}

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body{
  background: rgba(6, 11, 26, 1);
  background: var(--body-bg ); 
  font-family:"Roboto", sans-serif; 
  font-family:var(--font-name) ;

}
section{
  padding: 60px 0px;
}
a{
  text-decoration: none;
  color:#fff;
  color:var(--link-color)
}
h1, 
h2 {
  color: #fff;
  color: var(--heading-color);
font-size: 2rem;
}

.container{
  width:1140px;
  margin: auto;
  padding:0px 15px;
}
.description{
  color: rgba(255, 255, 255, 0.774);
  color: var(--small-text-color);
  line-height: 30px;
  font-size: .9rem;
}
.subtitle{
  font-size: 1rem;
color: #379cf6;
color: var(--primary-color);
font-weight: 400;
margin-bottom: 20px;
}
.light-theme .counter{
  background: var(--counter-section-bg);
}
.light-theme .secondary__btn{
  border: 1px solid #379cf6;
  border: 1px solid var(--primary-color);
color: #090238;
font-weight: 600;
}
.light-theme a{
  font-weight: 500;
}
@media only screen and (max-width:1024px){
  .container{
    width: 100%;
  }
}
@media only  screen and (max-width:768px){
 section{
  padding: 40px 0px;
 } 
}
